<template>
  <div
    v-if="renderComponent"
    class="sidebar-category-filter"
    :class="{ show: isActive }"
  >
    <SidebarCategory />

    <div class="category">
      <div class="category-wrap">
        <div class="tab-mob-show">
          <h3 class="category-heading border-none pb-15">
            {{ $t("search_help") }}
            <span>
              <b-nav-item
                class="close-sidebar tab-mob-show"
                @click="closeSidebar"
              >
                <b-img alt :src="closeIcon" />
              </b-nav-item>
            </span>
          </h3>
        </div>

        <h3 class="category-heading tab-mob-none mb-0">
          {{ $t("search_help") }}
        </h3>
        <div class="heading bold pt-3 pb-15">
          <button @click="resetFilter" class="filter-btn">
            {{ $t("clear_filter") }}
          </button>
        </div>
        <div
          class="filterCheck"
          v-for="prodFilter of productFilters"
          :key="prodFilter.attribute_code"
        >
          <div class="price" v-if="prodFilter.attribute_code == 'price'">
            <div :class="prodFilter.attribute_code">
              <div class="check-list-hd-category-checkbox">
                <h6
                  class="list-hd-category-checkbox"
                  v-b-toggle="prodFilter.attribute_code"
                >
                  <span class="category-icon">
                    <font-awesome-icon icon="caret-down" />
                  </span>
                  <span>{{ $t(prodFilter.label) }}</span>
                </h6>
                <b-collapse visible :id="prodFilter.attribute_code">
                  <div class="range-slider">
                    <div class="range-values d-flex justify-content-between">
                      <span
                        ><input
                          ref="priceFilter"
                          type="number"
                          :min="priceFilterMin"
                          :max="priceFilterMax"
                          @input="debounceMin"
                          :value="priceRange[0]"
                      /></span>
                      <span
                        ><input
                          ref="priceFilter"
                          type="number"
                          :min="priceFilterMin"
                          :max="priceFilterMax"
                          @input="debounceMax"
                          :value="priceRange[1]"
                      /></span>
                    </div>
                    <ClientOnly>
                      <VueRangeSlider
                        :min="priceFilterMin"
                        :max="priceFilterMax"
                        :lazy="true"
                        :formatter="formatter"
                        v-model="priceRange"
                        :logarithmic="true"
                        tooltip="active"
                        :enableCross="false"
                        :enable-cross="false"
                        ref="rangeSlider"
                      >
                      </VueRangeSlider>
                    </ClientOnly>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
          <div class="size" v-if="prodFilter.attribute_code != 'price'">
            <div class="check-list-hd-category-checkbox">
              <h6
                class="list-hd-category-checkbox"
                v-b-toggle="prodFilter.attribute_code"
              >
                <span class="category-icon">
                  <font-awesome-icon icon="caret-down" />
                </span>
                <span>{{ prodFilter.label }}</span>
              </h6>
              <b-collapse visible :id="prodFilter.attribute_code">
                <div
                  class="filterdata"
                  v-for="(brandsFilterItem, index) in readMore[
                    prodFilter.attribute_code
                  ]
                    ? prodFilter.options
                    : prodFilter.options.slice(0, 5)"
                  :key="index"
                >
                  <label
                    :for="brandsFilterItem.value"
                  >
                    <b-link 
                      :to="getFilterUrl({
                        attribute_code: prodFilter.attribute_code,
                        label: brandsFilterItem.label,
                        value: brandsFilterItem.value,
                        type_label: prodFilter.label
                      })"
                      class="d-flex align-items-center"
                    >
                      <input
                        type="checkbox"
                        :key="brandsFilterItem.value"
                        :name="brandsFilterItem.label"
                        :id="brandsFilterItem.value"
                        :ref="brandsFilterItem.value"
                        :value="brandsFilterItem.value"
                        v-model="selectedFilters"
                      />
                      <span
                        class="fake-input"
                        :style="{
                          background:
                            brandsFilterItem.swatch_data &&
                            brandsFilterItem.swatch_data.value,
                          borderColor:
                            brandsFilterItem.swatch_data &&
                            brandsFilterItem.swatch_data != '#ffffff' &&
                            brandsFilterItem.swatch_data.value,
                        }"
                      ></span>
                      <!-- @click.prevent -->
                      <span class="label-wrap">
                        <span class="fake-label">{{
                          brandsFilterItem.label
                        }}</span>
                        <span class="number-available"
                          >({{ brandsFilterItem.count }})</span
                        >
                      </span>
                    </b-link>
                  </label>
                </div>

                <div v-if="prodFilter.count > 5" class="show-more">
                  <b-link
                    v-if="readMore[prodFilter.attribute_code]"
                    @click="toggleReadMore(prodFilter.attribute_code)"
                    >{{ $t("less") }}</b-link
                  >
                  <b-link
                    v-else
                    @click="toggleReadMore(prodFilter.attribute_code)"
                  >
                    {{ $t("show") }} {{ prodFilter.count - 5 }} {{ $t("more") }}
                  </b-link>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <SidebarRatingComponent />
      </div>
      <button
        @click="closeSidebar"
        class="filter-btn show-more-results mob-tab-show"
      >
        {{ $t("look_at") }} {{ totalProducts }} {{ $t("result") }}
        <b-icon icon="chevron-right"></b-icon>
      </button>
    </div>
  </div>
</template>
<script>
import closeIcon from "@/esf_utrecht_bankxl/assets/images/close-icon.png";
import SidebarCategory from "@/esf_utrecht_bankxl/core/components/products/category-product/SidebarCategoryComponent";
import SidebarRatingComponent from "@/esf_utrecht_bankxl/core/components/products/category-product/SidebarRatingComponent";
import ClientOnly from "vue-client-only";
import VueRangeSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import { debounce } from "lodash";
import { slugify } from "@storefront/core/helpers";
import config from "@config";

export default {
  name: "SidebarComponent",
  props: ["isActive"],
  inject: ["openSidebar", "closeSidebar"],
  components: {
    SidebarCategory,
    SidebarRatingComponent,
    ClientOnly,
    VueRangeSlider,
  },
  data() {
    return {
      closeIcon,
      show: true,
      sliderIsLoaded: false,
      bla: false,
      minAngle: 10,
      maxAngle: 30,
      filterExpanded: false,
      readMore: {},
      minRangeFilter: 0,
      priceRange: [0, 0],
      renderComponent: true,
      selectedFilters: [],
    };
  },
  mounted() {
    this.forceRerender();
    this.priceRange = this.priceFilter;
    this.filterItemsSelected.forEach((filterItem) => {
      if (this.$refs[filterItem.value] && this.$refs[filterItem.value][0]) {
        this.$refs[filterItem.value][0].checked = true;
      }
    });
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    filterItemsSelected() {
      return this.$store.getters["category/getActiveFilter"];
    },
    isPriceFilterActive() {
      return this.$store.getters["category/getIsPriceFilterActive"];
    },
    priceFilterMin() {
      return this.$store.getters["category/getPriceFilterMin"];
    },
    priceFilterMax() {
      return this.$store.getters["category/getPriceFilterMax"];
    },
    priceFilter() {
      return this.$store.getters["category/getPriceFilter"];
    },
    productFilters() {
      var productFilters = JSON.parse(
        JSON.stringify(this.$store.getters["category/getProductFilters"])
      );
      if (
        typeof productFilters === "object" &&
        !Array.isArray(productFilters)
      ) {
        return [];
      }
      productFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.sort((a, b) => {
            return a.label < b.label ? -1 : 1;
          });
        }
      });
      return productFilters;
    },
    totalProducts() {
      return +this.$store.getters["category/getTotalProducts"];
    },
    aggregations() {
      return this.$store.getters["category/getAggregations"];
    },
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;
      setTimeout(() => {
        this.renderComponent = true;
      }, 500);
    },

    transformLabel(label) {
      return label.toLowerCase().replace(/\|/g, '').replace(/[\s/]+/g, '-');
    },

    getFilterUrl(filter) {
      // Make New URL
      const currentUrl = this.$route.fullPath;
      const [path, queryString] = currentUrl.split('?');
      const pathSegments = path.split('/');

      // Find the segment that starts with "f" (if any)
      const fIndex = pathSegments.findIndex(segment => segment.startsWith('f'));

      let basePath;
      let filterPath;
      if (fIndex !== -1) {
        basePath = pathSegments.slice(0, fIndex + 1).join('/');
        if (!basePath.endsWith('/')) {
          basePath += '/';
        }
        filterPath = pathSegments.slice(fIndex + 1).join('/');
      } else {
        basePath = path;
        filterPath = '';
      }

      let label = this.transformLabel(filter.label);
      let attributeCode = filter.attribute_code;
      let attributeSegments = filterPath ? filterPath.split('/') : [];

      // Check if the attribute code already exists in the URL
      const attributeIndex = attributeSegments.findIndex(segment => segment.startsWith(`${attributeCode}:`));
      
      if (attributeIndex !== -1) {
        // Append the new label to the existing attribute

        // Split the existing segment to check for the label match
        const existingSegment = attributeSegments[attributeIndex];
        const existingLabels = existingSegment.split(':')[1].split(',');

        if (existingLabels.includes(label)) {
          // Remove the label from the existing attribute
          const updatedLabels = existingLabels.filter(existingLabel => existingLabel !== label);
          if (updatedLabels.length > 0) {
            attributeSegments[attributeIndex] = `${attributeCode}:${updatedLabels.join(',')}`;
          } else {
            // If no labels are left, remove the attribute segment completely
            attributeSegments.splice(attributeIndex, 1);
          }

          // Construct the new path after removing the label
          const newFilterPath = attributeSegments.join('/');
          let newPath = `${basePath}${newFilterPath}`;

          if (newPath.endsWith("f/")) {
            newPath = newPath.slice(0, -2);
          }
          return queryString ? `${newPath}?${queryString}` : newPath;
        } else {
          attributeSegments[attributeIndex] += `,${label}`;
        }
      } else {
        // Add the new attribute and label
        attributeSegments.push(`${attributeCode}:${label}`);
        
        if (!basePath.endsWith("f/")) {
          basePath += "f/";
        }
      }

      // Make URL attributes in order
      attributeSegments = this.sortUrl(attributeSegments);

      // Construct the new path
      const newFilterPath = attributeSegments.join('/');
      let newPath = `${basePath}${newFilterPath}`;

      if (!newPath.endsWith("/")) {
        newPath += "/";
      }


      return queryString ? `${newPath}?${queryString}` : newPath;
    },
    
    // onChangeFilter(event, filter) {
    //   if (typeof filter != "undefined" && typeof filter.value != "undefined") {
    //     if (event.target.checked == true) {
    //       this.$store.commit("category/setActiveFilter", filter);
    //     } else {
    //       this.$store.commit("category/removeActiveFilter", filter);
    //     }
    //   }
    //   this.updateUrlFilters();
    // },

    debounceMin: debounce(function (event) {
      this.onPriceRangeMinChange(event);
    }, 500),
    debounceMax: debounce(function (event) {
      this.onPriceRangeMaxChange(event);
    }, 500),
    resetFilter() {
      Object.keys(this.$refs).forEach((el) => {
        if (
          this.$refs[el][0] &&
          this.$refs[el][0].type === "checkbox" &&
          this.$refs[el][0].checked === true
        ) {
          this.$refs[el][0].checked = false;
        }
      });

      this.$store.commit("category/resetActiveFilter");
      this.$store.commit("category/resetCurrentPage");
      this.$store.dispatch("category/loadCategoryFilters");

      const activeFilters = {};
      this.filterItemsSelected.forEach((val) => {
        if (activeFilters[val.attribute_code]) {
          activeFilters[val.attribute_code].push(val.value);
        } else {
          activeFilters[val.attribute_code] = [val.value];
        }
      });

      const path =
        "/" + this.category.url_path + "/" + this.slugify(activeFilters) + "/";
      const query = { ...this.$route.query };

      for (const q in query) {
        if (q.startsWith("filter-")) delete query[q];
      }
      for (const attr in activeFilters) {
        query[`filter-${attr}`] = activeFilters[attr].toString();
      }
      delete query["page"];
      if (query["page-size"] && query["page-size"] % 5 != 0) {
        query["page-size"] = Math.round(query["page-size"] / 5) * 5;
      }
      this.$router.replace({ path, query });
    },
    toggleReadMore(attribute_code) {
      if (this.readMore[attribute_code]) {
        this.readMore = {
          ...this.readMore,
          [attribute_code]: false,
        };
      } else {
        this.readMore = {
          ...this.readMore,
          [attribute_code]: true,
        };
      }
    },
    addPriceFilters() {
      const newFilters = this.$store.getters["category/getProductFilters"];
      if (Array.isArray(newFilters)) {
        const priceFilter = newFilters.find(
          (f) => f.attribute_code === "price"
        );
        if (
          !this.priceProdFilter ||
          !Object.keys(this.priceProdFilter).length
        ) {
          this.priceProdFilter = { ...priceFilter };
        }
      }
    },

    // sort URL as per aggregations
    sortUrl(attributeSegments) {

      // Fetch pre defined aggregations sequence
      const removePriceAndCategoryIdArray = this.aggregations.filter(aggregation => {
        return aggregation.attribute_code !== 'price' && aggregation.attribute_code !== 'category_id';
      });

      let getAttributeValues = removePriceAndCategoryIdArray.filter(aggregation => {
        return aggregation.attribute_code !== 'price' && aggregation.attribute_code !== 'category_id';
      });
      let predefinedOrder = getAttributeValues.map(aggregation => aggregation.attribute_code);

      // from here
      const filteredArray = attributeSegments.filter(item => item !== "");

      // Split and sort multiple values for each key
      const splitAndSortValues = filteredArray.map(item => {
          const [key, values] = item.split(":");
          const sortedValues = values.split(",").sort().join(",");
          return `${key}:${sortedValues}`;
      });

      // Sort based on predefined order
      const sortedArray = splitAndSortValues.sort((a, b) => {
        const aKey = a.split(":")[0];
        const bKey = b.split(":")[0];
        return predefinedOrder.indexOf(aKey) - predefinedOrder.indexOf(bKey);
      });

      return sortedArray;
    },

    // Update the URl according to filters
    updateUrlFilters() {

      // Fetch pre defined aggregations sequence
      const removePriceAndCategoryIdArray = this.aggregations.filter(aggregation => {
        return aggregation.attribute_code !== 'price' && aggregation.attribute_code !== 'category_id';
      });

      let getAttributeValues = removePriceAndCategoryIdArray.filter(aggregation => {
        return aggregation.attribute_code !== 'price' && aggregation.attribute_code !== 'category_id';
      });
      let predefinedOrder = getAttributeValues.map(aggregation => aggregation.attribute_code);

      const activeFilters = {};
      this.filterItemsSelected.forEach((val) => {
        if (activeFilters[val.attribute_code]) {
          activeFilters[val.attribute_code].push(val.label);
        } else {
          activeFilters[val.attribute_code] = [val.label];
        }
      });

      let orderedActiveFilters = {};
      predefinedOrder.forEach(code => {
        if (Object.hasOwn(activeFilters, code)) {
          orderedActiveFilters[code] = activeFilters[code].slice().sort();
        }
      });

      const path =
        "/" + this.category.url_path + "/" + this.slugify(orderedActiveFilters) + "/";
      const query = { ...this.$route.query };

      for (const q in query) {
        if (q.startsWith("filter-") && q !== "filter-price") {
          delete query[q];
        }
      }
      // for (const attr in activeFilters) {
      //   query[`filter-${attr}`] = activeFilters[attr].toString();
      // }
      delete query["page"];
      if (query["page-size"] && query["page-size"] % 5 != 0) {
        query["page-size"] = Math.round(query["page-size"] / 5) * 5;
      }
      this.$router.replace({ path, query });
    },
    
    onPriceRangeMinChange(event) {
      if (
        Number(event.target.value) <= this.priceRange[1] &&
        Number(event.target.value) >= Number(event.target.min)
      ) {
        this.priceRange[0] = Number(event.target.value);
        this.processPricePriceRangeChange(this.priceRange);
        this.$refs.rangeSlider[0].setValue(this.priceRange);
      }
    },
    onPriceRangeMaxChange(event) {
      if (
        Number(event.target.value) >= this.priceRange[0] &&
        Number(event.target.value) <= Number(event.target.max)
      ) {
        this.priceRange[1] = Number(event.target.value);
        this.processPricePriceRangeChange(this.priceRange);
        this.$refs.rangeSlider[0].setValue(this.priceRange);
      }
    },
    processPricePriceRangeChange(newValue) {
      if (this.$refs.priceFilter) {
        const inputMin = this.$refs.priceFilter[0];
        if (inputMin && parseInt(newValue[0]) <= parseInt(newValue[1])) {
          this.$store.commit("category/setPriceFilter", [...newValue]);
          const path = "/" + this.category.url_path + "/";
          const query = { ...this.$route.query };

          if (this.isPriceFilterActive)
            query["filter-price"] = `${newValue[0]}-${newValue[1]}`;
          else delete query["filter-price"];
          delete query["page"];

          this.$router.replace({ path, query });
        }
      }
    },
    slugify(values) {
      var returnValues = [];
      let newValues = { ...values };
      Object.keys(newValues).forEach((key) => {
        newValues[key] = this.findAttributeValue(key, newValues[key]);
        returnValues.push(slugify(key, newValues[key]));
      });
      return (
        (returnValues.length > 0 && config.filter_name
          ? "/" + config.filter_name + "/"
          : "") + returnValues.join("/")
      );
    },
    findAttributeValue(key, value) {
      this.aggregations.forEach((filter) => {
        if (filter.attribute_code == key) {
          value.forEach((val, index) => {
            const foundValue = filter.options.find((el) => el.value == val);
            if (foundValue) {
              value[index] = foundValue.label;
            }
          });
        }
      });
      return value;
    },

    extractFilterParams(fullPath) {
      const filterParams = {};
      const pathParts = fullPath.split('/');

      pathParts.forEach(part => {
        if (part.includes(':')) {
          const [key, value] = part.split(':');
          const values = value.split(',');
          filterParams[key] = values.map(val => ({
            attribute_code: key,
            label: val,
            // Add additional filter details if needed
          }));
        }
      });

      return filterParams;
    },

    fetchTypeLabelAndValue(attributeCode, label) {
      let typeLabel = null;
      let value = null;

      // Find the aggregation object matching attribute_code
      const aggregation = this.aggregations.find(aggregation => aggregation.attribute_code === attributeCode);

      if (aggregation) {
        // Find the option object matching label within the aggregation options
        const option = aggregation.options.find(option => this.transformLabel(option.label) === label);

        if (option) {
          typeLabel = aggregation.label; // Assuming type_label is mapped to aggregation.label
          value = option.value;
        }
      }

      return {
        attribute_code: attributeCode,
        label: label,
        value: value,
        type_label: typeLabel
      };
    },
  },
  updated() {
    if (this.filterItemsSelected && this.filterItemsSelected.length) {
      this.filterItemsSelected.forEach((filter) => {
        if (this.$refs[filter.value] && this.$refs[filter.value][0]) {
          this.$refs[filter.value][0].checked = true;
        }
      });
    }
  },
  watch: {
    priceFilter(val) {
      this.priceRange = JSON.parse(JSON.stringify(val));
      this.$forceUpdate();
    },
    priceRange(newValue) {
      if (
        !(
          newValue[0] === this.priceFilter[0] &&
          newValue[1] === this.priceFilter[1]
        )
      ) {
        this.processPricePriceRangeChange(newValue);
      }
    },
    "category.name"() {
      Object.keys(this.$refs).forEach((el) => {
        if (
          this.$refs[el][0] &&
          this.$refs[el][0].type === "checkbox" &&
          this.$refs[el][0].checked === true
        ) {
          this.$refs[el][0].checked = false;
        }
      });
      this.$store.commit("category/resetActiveFilter");
      this.$store.commit("category/resetCurrentPage");
    },

    // Do Filter Commits as per url changes
    $route(to, from) {
      // Correct page-size in query
      if (to.query && to.query['page-size'] === 'NaN') {
        this.$router.replace({
          ...to,
          query: {
            ...to.query,
            'page-size': '15'
          }
        });
      }

      const fromFullPath = from.fullPath;
      const toFullPath = to.fullPath;

      if (fromFullPath !== toFullPath) {
        const fromFilterParams = this.extractFilterParams(fromFullPath);
        const toFilterParams = this.extractFilterParams(toFullPath);

        const changes = {
          added: [],
          removed: []
        };

        // Determine added and removed filters
        for (const key in toFilterParams) {
          if (fromFilterParams[key]) {
            const addedFilters = toFilterParams[key].filter(
              item => !fromFilterParams[key].some(f => f.label === item.label)
            );
            const removedFilters = fromFilterParams[key].filter(
              item => !toFilterParams[key].some(f => f.label === item.label)
            );

            changes.added.push(...addedFilters);
            changes.removed.push(...removedFilters);
          } else {
            changes.added.push(...toFilterParams[key]);
          }
        }

        for (const key in fromFilterParams) {
          if (!toFilterParams[key]) {
            changes.removed.push(...fromFilterParams[key]);
          }
        }

        if(changes.added.length > 0) {
          const filter = this.fetchTypeLabelAndValue(changes.added[0].attribute_code, changes.added[0].label);

          // add value from input checkbox state array
          this.selectedFilters.push(filter.value);

          // commit the set active filter
          this.$store.commit("category/setActiveFilter", filter);
        }

        if(changes.removed.length > 0) {
          const filter = this.fetchTypeLabelAndValue(changes.removed[0].attribute_code, changes.removed[0].label);

          // remove value from input checkbox state array
          const index = this.selectedFilters.indexOf(filter.value);
          if (index > -1) {
            this.selectedFilters.splice(index, 1);
          }

          // commit the remove active filter
          this.$store.commit("category/removeActiveFilter", filter);
        }
      }
    }
  },
  created() {
    this.min = 0;
    this.max = 250;
    this.enableCross = false;
    this.formatter = "€ {value}";
  },
  metaInfo() {
    let filtered =
      (this.filterItemsSelected && this.filterItemsSelected.length > 0) ||
      this.isPriceFilterActive;
    return {
      meta: [
        {
          name: "robots",
          content: filtered ? "noindex,nofollow" : "index,follow",
        },
      ],
    };
  },
};
</script>
